




























import TheSidebar from '@/containers/TheSidebar.vue'
import TheHeader from '@/containers/TheHeader.vue'
import TheFooter from '@/containers/TheFooter.vue'
import TheAside from "@/containers/TheAside.vue";


import {vxm} from "@/store";
import {Component, Vue, Watch} from 'vue-property-decorator';
import BioModal from "@shared_vue/components/modals/BioModal.vue";

@Component({
  components: {
    TheSidebar,
    TheHeader,
    TheFooter,
    TheAside,
    BioModal
  },
})

export default class TheContainer extends Vue {
  private ui = vxm.ui;
  private auth = vxm.auth;
  private blockBio = false;
  @Watch('auth.bioRequired')
  onBioRequired(val: boolean, oldval: boolean) {
    this.blockBio = val;
  }
}

