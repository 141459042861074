

























import {vxm} from "@/store";
import {Component, Vue, Prop, PropSync} from 'vue-property-decorator';


@Component
export default class BioModal extends Vue {
  @PropSync('showvar', { type: Boolean }) syncedShow!: boolean;
  private ui = vxm.ui;



}
